import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

import "../controllers/index";

config.mutateApproach = "sync";

// TODO: Switch to a custom kit where we don't have to list every icon in use
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCircleCheck,
  faCircleUser,
  faClipboardList,
  faClose,
  faEllipsisV,
  faFileInvoiceDollar,
  faGear,
  faGripLines,
  faMagnifyingGlass,
  faPaperclip,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendar,
  faClock as faRegularClock,
  faFolder,
  faMessage,
  faTrashCan as faRegularTrashCan,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCalendar,
  faCircleCheck,
  faCircleUser,
  faClipboardList,
  faClose,
  faEllipsisV,
  faFileInvoiceDollar,
  faFolder,
  faGear,
  faGripLines,
  faMagnifyingGlass,
  faMessage,
  faPaperclip,
  faPlus,
  faRegularClock,
  faRegularTrashCan,
  faTrash,
);

dom.watch();
