import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "resource",
    "resourceIndex",
    "resourceId",
    "resourceShopId",
    "resourcePosition",
    "resourceName",
    "list",
    "dialogText",
    "removeResourceButton",
    "removeIcon"
  ]

  connect() {}

  addResource(event) {
    const lastIndex = this.resourceIndexTargets.slice(-1)[0];
    const lastPosition = this.resourcePositionTargets.slice(-1)[0];

    this.listTarget.appendChild(this.resourceTargets.slice(-1)[0].cloneNode(true));

    const newIndexValue = Number(lastIndex.value) + 1;
    const newPositionValue = Number(lastPosition.value) + 1;

    const newIndex = this.resourceIndexTargets.slice(-1)[0];
    const newId = this.resourceIdTargets.slice(-1)[0];
    const newShopId = this.resourceShopIdTargets.slice(-1)[0];
    const newPosition = this.resourcePositionTargets.slice(-1)[0];
    const newName = this.resourceNameTargets.slice(-1)[0];
    const removeIcon = this.removeIconTargets.slice(-1)[0];

    newIndex.value = newIndexValue;

    newId.name = `the_shop[job_task_resources_attributes][${newIndexValue}][id]`;
    newId.id = `the_shop_job_task_resources_attributes_${newIndexValue}_id`;
    newId.value = "";

    newShopId.name = `the_shop[job_task_resources_attributes][${newIndexValue}][the_shop_id]`;
    newShopId.id = `the_shop_job_task_resources_attributes_${newIndexValue}_the_shop_id`;

    newPosition.name = `the_shop[job_task_resources_attributes][${newIndexValue}][position]`;
    newPosition.id = `the_shop_job_task_resources_attributes_${newIndexValue}_position`;
    newPosition.value = newPositionValue;

    newName.name = `the_shop[job_task_resources_attributes][${newIndexValue}][name]`;
    newName.id = `the_shop_job_task_resources_attributes_${newIndexValue}_name`;
    newName.value = "";

    removeIcon.removeAttribute("data-id");
    removeIcon.removeAttribute("data-name");
  }

  openDialog(event) {
    const id = event.currentTarget.attributes["data-id"]?.value;
    const name = event.currentTarget.attributes["data-name"]?.value;

    if (id === undefined) {
      event.stopImmediatePropagation();
      event.currentTarget.parentElement.remove();
    } else {
      this.removeResourceButtonTarget.setAttribute("action", `/job_task_resources/${id}`);
      this.dialogTextTarget.innerHTML = `Are you sure you want to delete ${name} from the resource list? Deleting this resource will leave any tasks that were assigned to it without a resource assignment.`;
    }
  }

  sort(event) {
    this.resourcePositionTargets.forEach((position, index) => {
      position.value = index + 1;
    });
  }
}
