import Dialog from "@stimulus-components/dialog"

export default class extends Dialog {
  connect() {
    super.connect()
  }

  // Function to override on open.
  // open() {
  //   super.open()
  // }

  // Function to override on close.
  // close() {
  //   super.close()
  // }

  // Function to override on backdropClose.
  // backdropClose() {
  //   super.backdropClose()
  // }
}
